import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {
  onSubmission,
  selectLineManager,
} from "../../app/features/lineManagerSlice";
import Button from "../../components/button/Button";
import OutcomeLeft from "../../containers/outcome-left/OutcomeLeft";
import OutcomeRight from "../../containers/outcome-right/OutcomeRight";

import { selectAuth } from "../../app/features/authSlice";
import Spinner from "../../components/spinner/Spinner";
import { getEmailBody } from "../../containers/outcome-sheet-texts/OutcomeSheetTexts";
import {
  AMBER_OUTCOME_NEXT_STEPS,
  GREEN_OUTCOME_NEXT_STEPS,
  RED_OUTCOME_NEXT_STEPS,
} from "../../utils/utils";
import warning from "../../assets/warningIcon.png";
import success from "../../assets/successIcon.png";
import question from "../../assets/questionIcon.png";
import info from "../../assets/infoIcon.png";

const OutcomeSheet = (props) => {
  const [step, setStep] = useState(0);
  const [caseId, setCaseId] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const pdfExportComponent = React.useRef(null);
  
  const {
    name,
    superVisorName,
    email,
    supervisorEmail,
    positionId,
    positionTitle,
    jobProfile,
    location,
    lmEC,
    lmEvpGrouping,
    lmOrgUnit5,
    managementLevelOfLineManger,
    compressionOfTeam,
    spanOfControlOfTeam,
    spanOfControlOfTeamToBe,
    maxNoOfLayersOfTeam,
    maxNoOfLayersOfTeamToBe,
    sameJGReportingsInTeamOfLineManager,
    adminParameters,
    maxNoOfLayers,
  } = useSelector(selectAuth);
  const {
    managementLevels,
    changes,
    coeImpact,
    teamDetails,
    isSubmitted,
    jobDescriptionFile,
  } = useSelector(selectLineManager);
  const {
    compressionGREENGreaterAndEqualTo,
    compressionGREENLessAndEqualTo,
    compressionORANGEUPPERGreaterAndEqualTo,
    compressionORANGEUPPERLessAndEqualTo,
    compressionREDGreaterAndEqualTo,
    compressionREDLessAndEqualTo,
    compressionORANGELOWERGreaterAndEqualTo,
    compressionORANGELOWERLessAndEqualTo,
    layersGREENGreaterAndEqualTo,
    layersGREENLessAndEqualTo,
    layersORANGEGreaterAndEqualTo,
    layersORANGELessAndEqualTo,
    spanOfControlGREENGreaterAndEqualTo,
    spanOfControlGREENLessAndEqualTo,
    spanOfControlORANGEGreaterAndEqualTo,
    spanOfControlORANGELessAndEqualTo,
    spanOfControlREDGreaterAndEqualTo,
    spanOfControlREDLessAndEqualTo,
    topWeightGREENLessAndEqualTo,
    sameJobGroupReportingGREENGreaterAndEqualTo,
    spreadOverJGsORANGELessAndEqualTo,
    sameJobGroupReportingGREENLessAndEqualTo,
  } = adminParameters;

  if (isSubmitted && step === 2) {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }

  const aiChanges = [];
  if (changes.length !== 0) {
    changes.map((v) => {
      if (!v.isDuplicated && !v.isRemoved) {
        aiChanges.push(v);
      }
      return aiChanges;
    });
  }

  let aiOutcomes = [];

  let aiSupplimentaryTexts = [];

  let cousinProfileTextForEmail = "";
  if (aiChanges.length !== 0) {
    cousinProfileTextForEmail = aiChanges.map(
      ({ jobProfile, cousinProfiles, positionTitle, aiManagementLevel }) => {
        const generic = `00000000`;

        if (jobProfile.includes(generic) && cousinProfiles) {
          return `<tr>
            <th align="center" style="vertical-align:top;">
              <span style="font-size:15px;margin: 5px 5px 0 10px;color:#dd1d21"
                >➥</span
              >
            </th>
            <th style="margin:0 5px;">
              <p style="margin:0;font-size:12.75px;text-align:justify">
                For position ${positionTitle} you have selected a generic
                profile at ML ${aiManagementLevel}. Please note that when
                creating this position in Workday you will be requested to
                select a job profile from the job catalogue. In the same job
                family the following profiles are available at the selected ML
                ${aiManagementLevel}.
              </p>
              ${
                cousinProfiles.length !== 0
                  ? cousinProfiles
                      .map((value) => {
                        return `<p
                    style="margin:0;font-size:12.75px;text-align:justify"
                  >
                     <span
                  style="font-size:15px;margin: 5px 5px 0 10px;color:#dd1d21"
                  >∎</span
                > ${value.Job_Profile_With_Job_ID}
                  </p>`;
                      })
                      .join("")
                  : `<p
                    style="margin:0;font-size:12.75px;text-align:justify;color:#dd1d21"
                  >
                    No other Job Profiles found at this Management Level
                  </p>`
              }
            </th>
          </tr> `;
        }
      }
    );
  }

  aiChanges.length !== 0 &&
    aiChanges.map(
      ({
        positionTitle,
        positionId,
        initialManagementLevel,
        ManagementLevel,
        aiManagementLevel,
        confidence,
        isChanged,
      }) => {
        if (
          confidence === "medium" &&
          ManagementLevel === aiManagementLevel &&
          ManagementLevel < initialManagementLevel
        ) {
          aiSupplimentaryTexts.push(
            `For position ${positionTitle} ${
              isChanged ? ` - ${positionId}` : ""
            }: You have adjusted your original Management Level selection upwards. Alternatively, you could consider rebalancing accountabilities within your team and/or slightly reduce the requirements for this position to stay with your initial selected Management Level ${initialManagementLevel}`
          );
        } else if (
          (confidence === "medium" && ManagementLevel > aiManagementLevel) ||
          (confidence === "high" &&
            ManagementLevel > aiManagementLevel &&
            aiManagementLevel < initialManagementLevel)
        ) {
          aiSupplimentaryTexts.push(
            `For Position ${`
                      ${positionTitle}
                      ${isChanged ? ` - ${positionId}` : ""}
                   `}: Review the accountabilities of the role as the position information contained elements of a higher Management Level. Ensure to align the accountabilities with the Management Level. You may use the Job Profile that you selected as a reference point.`
          );
        } else if (
          confidence === "high" &&
          ManagementLevel < aiManagementLevel
        ) {
          aiSupplimentaryTexts.push(
            `For Position ${`
                      ${positionTitle}
                      ${isChanged ? ` - ${positionId}` : ""}
                   `}: EVA analysis suggests a lower level than this level you selected. Consider lowering Management Level accordingly.`
          );
        } else if (
          confidence === "high" &&
          ManagementLevel > aiManagementLevel
        ) {
          aiSupplimentaryTexts.push(
            `For Position ${`
                      ${positionTitle}
                      ${isChanged ? ` - ${positionId}` : ""}
                   `}: EVA analysis suggests that the responsibilities of the position are heavy in comparison to the Management Level you selected. Consider re-allocation of accountabilities. You may use the Job Profile that you selected as a reference point.`
          );
        } else if (
          confidence === "medium" &&
          ManagementLevel < aiManagementLevel
        ) {
          aiSupplimentaryTexts.push(
            `For Position ${`
                      ${positionTitle}
                      ${isChanged ? ` - ${positionId}` : ""}
                   `}: The position information showed significant elements of a lower Management Level. Consider whether the Management Level can be reduced and ensure the allocation of accountabilities aligns.`
          );
        }
        return aiSupplimentaryTexts;
      }
    );

  let aiSupplimentaryTextsForEmail = "";
  if (aiSupplimentaryTexts.length !== 0) {
    aiSupplimentaryTextsForEmail = aiSupplimentaryTexts.map((value, index) => {
      return `
        <tr>
          <th align="center" style="vertical-align:top;">
            <span style="font-size:15px;margin: 5px 5px 0 10px;color:#dd1d21"
              >➥</span
            >
          </th>
          <th style="margin:0 5px;">
            <p style="margin:0;font-size:12.75px;text-align:justify">
              ${value}
            </p>
          </th>
        </tr>
      `;
    });
  }

  changes.length !== 0 &&
    changes.map((value) => {
      // POP UP 1
      if (
        (value.confidence === "high" || value.confidence === "medium") &&
        value.aiManagementLevel > value.initialManagementLevel
      ) {
        //green
        if (
          value.ManagementLevel === value.aiManagementLevel &&
          (value.confidence === "high" || value.confidence === "medium")
        ) {
          aiOutcomes.push("green");
        }
        //red
        else if (
          (value.confidence === "high" &&
            value.aiManagementLevel !== value.ManagementLevel) ||
          (value.confidence === "medium" &&
            value.aiManagementLevel - value.ManagementLevel === 2 &&
            value.aiManagementLevel - value.initialManagementLevel === 1)
        ) {
          aiOutcomes.push("red");
        }
        //amber
        else {
          aiOutcomes.push("amber");
        }
      }
      // POP UP 3
      else if (
        (value.confidence === "high" || value.confidence === "medium") &&
        value.aiManagementLevel === value.initialManagementLevel
      ) {
        //green
        if (
          value.ManagementLevel === value.aiManagementLevel &&
          (value.confidence === "high" || value.confidence === "medium")
        ) {
          aiOutcomes.push("green");
        }
        //red
        else if (
          value.confidence === "high" &&
          value.aiManagementLevel !== value.ManagementLevel
        ) {
          aiOutcomes.push("red");
        }
        //amber
        else if (
          value.confidence === "medium" &&
          value.aiManagementLevel !== value.ManagementLevel
        ) {
          aiOutcomes.push("amber");
        }
      }
      // POP UP 4
      else if (
        (value.confidence === "high" || value.confidence === "medium") &&
        value.aiManagementLevel < value.initialManagementLevel
      ) {
        if (
          //green
          value.confidence === "high" &&
          value.ManagementLevel === value.aiManagementLevel
        ) {
          aiOutcomes.push("green");
        }
        //amber
        else if (
          value.confidence === "medium" &&
          value.aiManagementLevel === value.ManagementLevel
        ) {
          aiOutcomes.push("amber");
        }
        //amber
        else if (
          (value.confidence === "high" || value.confidence === "medium") &&
          value.aiManagementLevel !== value.ManagementLevel
        ) {
          aiOutcomes.push("amber");
        }
      }
      //POP UP 2
      else if (value.confidence === "low") {
        aiOutcomes.push("amber");
      }
      return aiOutcomes;
    });

  const allEqual = (arr) => arr.every((v) => v === "green");

  let aiOutcome = "amber";
  if (aiOutcomes.includes("red")) {
    aiOutcome = "red";
  } else if (allEqual(aiOutcomes)) {
    aiOutcome = "green";
  }

  const sameJGReportingsTobe = managementLevels.filter(
    (val) => val.managementLevel - managementLevelOfLineManger === 0 && val.managementLevel!=null
  ).length;

  const groupByThree = ([a, b, c, ...rest]) => {
    if (rest.length === 0) return [[a, b, c].filter((x) => x !== undefined)];
    return [[a, b, c]].concat(groupByThree(rest));
  };

  let jobDescriptions = [];
  teamDetails.map((value) => {
    if (value.attachment !== null) {
      jobDescriptions.push({
        positionId: value.positionId,
        positionTitle: value.positionTitle,
        attachment: value.attachment,
        jdUrl: value.jdUrl,
        isChanged: value.isChanged,
        isNewPosition: value.isNewPosition,
      });
    }
    return jobDescriptions;
  });

  let jobDescriptionLinks = "";
  if (jobDescriptions.length !== 0) {
    jobDescriptionLinks = jobDescriptions.map((link) => {
      return `
              <tr>
                  <th style="font-size: 11px;padding: 5px 8px;background-color: #e9e7e7;text-align: left">
                      <p style="margin:0;font-size:12.75px;text-align:left">
                          <a href=${
                            link.jdUrl
                          } style="text-decoration:none;color:#003c88">
                             📑 ${
                               link.isChanged
                                 ? `${link.positionTitle.toUpperCase()} - ${
                                     link.positionId
                                   }`
                                 : link.positionTitle.toUpperCase()
                             }
                          </a>
                      </p>
                  </th>
              </tr>`;
    });
  }

  let ragIndicatorTable = "";
  if (aiChanges.length !== 0) {
    ragIndicatorTable =
      changes.length !== 0 &&
      changes.map((value) => {
        let output = "Non Conclusive";
        let background = "#eb8705";
        // POP UP 1
        if (
          (value.confidence === "high" || value.confidence === "medium") &&
          value.aiManagementLevel > value.initialManagementLevel
        ) {
          //green
          if (
            value.ManagementLevel === value.aiManagementLevel &&
            (value.confidence === "high" || value.confidence === "medium")
          ) {
            output = "Aligned";
            background = "#0ab15e";
          }
          //red
          else if (
            (value.confidence === "high" &&
              value.aiManagementLevel !== value.ManagementLevel) ||
            (value.confidence === "medium" &&
              value.aiManagementLevel - value.ManagementLevel === 2 &&
              value.aiManagementLevel - value.initialManagementLevel === 1)
          ) {
            output = "Misaligned";
            background = "#dd1d21";
          }
          //amber
          else {
            output = "Non Conclusive";
            background = "#eb8705";
          }
        }
        // POP UP 3
        else if (
          (value.confidence === "high" || value.confidence === "medium") &&
          value.aiManagementLevel === value.initialManagementLevel
        ) {
          //green
          if (
            value.ManagementLevel === value.aiManagementLevel &&
            (value.confidence === "high" || value.confidence === "medium")
          ) {
            output = "Aligned";
            background = "#0ab15e";
          }
          //red
          else if (
            value.confidence === "high" &&
            value.aiManagementLevel !== value.ManagementLevel
          ) {
            output = "Misaligned";
            background = "#dd1d21";
          }
          //amber
          else if (
            value.confidence === "medium" &&
            value.aiManagementLevel !== value.ManagementLevel
          ) {
            output = "Non Conclusive";
            background = "#eb8705";
          }
        }
        // POP UP 4
        else if (
          (value.confidence === "high" || value.confidence === "medium") &&
          value.aiManagementLevel < value.initialManagementLevel
        ) {
          if (
            //green
            value.confidence === "high" &&
            value.ManagementLevel === value.aiManagementLevel
          ) {
            output = "Aligned";
            background = "#0ab15e";
          }
          //amber
          else if (
            value.confidence === "medium" &&
            value.aiManagementLevel === value.ManagementLevel
          ) {
            output = "Non Conclusive";
            background = "#eb8705";
          }
          //amber
          else if (
            (value.confidence === "high" || value.confidence === "medium") &&
            value.aiManagementLevel !== value.ManagementLevel
          ) {
            output = "Non Conclusive";
            background = "#eb8705";
          }
        }
        //POP UP 2
        else if (value.confidence === "low") {
          output = "Non Conclusive";
          background = "#eb8705";
        }
        if (!value.isDuplicated && !value.isRemoved) {
          return ` <tr>
              <th
                style="font-size: 12.5px;padding: 5px 8px;background-color: #e9e7e7;text-align: left"
              >
                <p style="margin:0;font-size:11px;text-align:left">
                    ${
                      value.isChanged
                        ? `${value.positionTitle.toUpperCase()} - ${
                            value.positionId
                          }`
                        : value.positionTitle.toUpperCase()
                    }
                </p>
              </th>
              <th
                style="font-size: 12.5px;padding:5.5px 10px;background-color:${background};text-align: center;font-family:ShellMedium;"
              >
                ${output}
              </th>
            </tr>`;
        }
      });
  }

  const coe = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(coeImpact);

  const finalTeamToShow = teamDetails.filter((e) => !e.isActionNotAllowed);

  let reasonsForActions = [];
  teamDetails.map((position) => {
    if (position.comments) {
      reasonsForActions.push({
        positionTitle: position.positionTitle,
        comment: position.comments,
        positionId: position.positionId,
        isChanged: position.isChanged,
        isNewPosition: position.isNewPosition,
        isRemoved: position.isRemoved,
      });
    }
    return reasonsForActions;
  });

  const teamStructureForOutcomeEmail = groupByThree(finalTeamToShow).map(
    (positions, index) =>
      ReactDOMServer.renderToStaticMarkup(
        <tr key={index}>
          {positions.length !== 0 &&
            positions.map((position) => {
              let output = null;
              let borderColor = "1px solid #404040";
              if (position.isChanged) {
                borderColor = "3px solid #6495ed";
              } else if (position.isDuplicated) {
                borderColor = "3px solid #800080";
              } else if (position.isNewPosition) {
                borderColor = "3px solid #0ab15e";
              } else if (position.isRemoved) {
                borderColor = "3px solid #ff0000";
              }

              let padding = "5.5px 6px";
              if (
                position.isChanged ||
                position.isDuplicated ||
                position.isNewPosition ||
                position.isRemoved
              ) {
                padding = "4px 4.5px";
              }

              if (position.isManager && !position.isActionNotAllowed) {
                output = (
                  <td
                    style={{
                      width: "275px",
                      padding: 0,
                      color: "#153643",
                      margin: "0 2px",
                    }}
                    key={position.positionId}
                  >
                    <table style={{ padding: 0, width: "100%", margin: 0 }}>
                      <tbody>
                        <tr>
                          <th
                            style={{
                              width: "100%",
                              height: "118px",
                              border: borderColor,
                              padding: `${padding}`,
                              backgroundColor: "#89cfdc",
                              textAlign: "left",
                            }}
                          >
                            {position.positionUnfilled ? (
                              <p
                                style={{
                                  margin: "2px 0",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  color: "#ff0000",
                                  textTransform: "uppercase",
                                  fontStyle: "italic",
                                }}
                              >
                                Unfilled Position
                              </p>
                            ) : null}
                            <p
                              style={{
                                margin: "5px 0",
                                fontSize: "11px",
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {position.positionTitle}
                            </p>
                            <p
                              style={{
                                color:
                                  position.isNewPosition ||
                                  position.isDuplicated
                                    ? "#009eb4"
                                    : "#153643",
                                margin: "5px 0",
                                fontSize: "10px",
                                fontWeight: "500",
                              }}
                            >
                              Position Number : {position.positionId}
                            </p>
                            <p
                              style={{
                                margin: "5px 0",
                                fontSize: "10px",
                                fontWeight: "500",
                              }}
                            >
                              Job Profile : {position.jobProfile}
                            </p>
                            <p
                              style={{
                                margin: "5px 0",
                                fontSize: "11px",
                                fontWeight: "500",
                              }}
                            >
                              Location: {position.location}
                            </p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                );
              } else if (!position.isActionNotAllowed) {
                output = (
                  <td
                    key={position.positionId}
                    style={{
                      width: "275px",
                      padding: 0,
                      color: "#153643",
                      margin: "0 2px",
                    }}
                  >
                    <table style={{ padding: 0, width: "100%", margin: 0 }}>
                      <tbody>
                        <tr>
                          <th
                            style={{
                              width: "250px",
                              height: "118px",
                              border: borderColor,
                              padding: `${padding}`,
                              backgroundColor: `${
                                position.showBlue ? "#89cfdc" : "#f7f7f7"
                              }`,
                              textAlign: "left",
                            }}
                          >
                            {position.positionUnfilled ? (
                              <p
                                style={{
                                  margin: "2px 0",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  color: "#f38840",
                                  textTransform: "uppercase",
                                  fontStyle: "italic",
                                }}
                              >
                                Unfilled Position
                              </p>
                            ) : null}
                            <p
                              style={{
                                margin: "5px 0",
                                fontSize: "11px",
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {position.positionTitle}
                            </p>
                            <p
                              style={{
                                color:
                                  position.isNewPosition ||
                                  position.isDuplicated
                                    ? "#f7f7f7"
                                    : "#153643",
                                margin: "5px 0",
                                fontSize: "10px",
                                fontWeight: "500",
                              }}
                            >
                              Position Number :{" "}
                              {position.isNewPosition || position.isDuplicated
                                ? ""
                                : position.positionId}
                            </p>
                            <p
                              style={{
                                margin: "5px 0",
                                fontSize: "10px",
                                fontWeight: "500",
                              }}
                            >
                              Job Profile : {position.jobProfile}
                            </p>
                            <p
                              style={{
                                margin: "5px 0",
                                fontSize: "11px",
                                fontWeight: "500",
                              }}
                            >
                              Location: {position.location}
                            </p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                );
              }
              return output;
            })}
        </tr>
      )
  );

  let reasonForActionsBody = "";
  if (reasonsForActions.length !== 0) {
    reasonForActionsBody = `<h1 style="font-size:14px;margin:15px 0 15px 5px;">REASONS FOR ACTION(S) :</h1>
          <ul style="list-style-type:none;padding:4px;margin: 5px 0px;">
              ${reasonsForActions
                .map(
                  (reason) =>
                    `<li>
                    <p style="margin:5px 0;font-size:12.75px;text-align:justify;">
                      <strong>${
                        reason.isChanged || reason.isRemoved
                          ? `${reason.positionTitle} - ${reason.positionId}`
                          : `${reason.positionTitle}`
                      }</strong>: ${reason.comment}
                    </p>
                  </li>`
                )
                .join("")}
          </ul>`;
  }

  let compression = 0;
  const managementLevelsForCompressionCalculation = managementLevels.filter(
    (val) => val.managementLevel !== null
  );
  const values = managementLevelsForCompressionCalculation.map(
    (val) => val.managementLevel - managementLevelOfLineManger
  );
  if (values.length !== 0) {
    compression = parseFloat(
      (values.reduce((val, res) => val + res, 0) / values.length).toFixed(2)
    );
  }

  let topWeight = 0;
  let managementLevelValue = 0;
  const managementLevelOneBelow = managementLevels.filter(
    (value) => value.managementLevel - managementLevelOfLineManger === 1
  );
  if (managementLevelOneBelow.length !== 0) {
    topWeight = managementLevelOneBelow.length / managementLevels.length;
    managementLevelValue = managementLevelOneBelow[0].managementLevel;
  }

  const jgDistribution = () => {
    const array = managementLevels.map((value) => value.managementLevel);
    const values = new Set(array);
    return [...values];
  };

  const jgDistributionValue = jgDistribution().length;

  let overallOutcomeWithoutSameJG = "red";
  if (
    (compression >= compressionGREENGreaterAndEqualTo &&
      compression <= compressionGREENLessAndEqualTo) ||
    (compression >= compressionORANGELOWERGreaterAndEqualTo &&
      compression <= compressionORANGELOWERLessAndEqualTo &&
      topWeight <= topWeightGREENLessAndEqualTo &&
      compression > compressionOfTeam) ||
    (compression >= compressionORANGEUPPERGreaterAndEqualTo &&
      compression <= compressionORANGEUPPERLessAndEqualTo &&
      compression <= compressionOfTeam) ||
    (spanOfControlOfTeamToBe === 0 && compression === 0)
  ) {
    overallOutcomeWithoutSameJG = "green";
  } else if (
    (compression >= compressionORANGELOWERGreaterAndEqualTo &&
      compression <= compressionORANGELOWERLessAndEqualTo) ||
    (compression >= compressionORANGEUPPERGreaterAndEqualTo &&
      compression <= compressionORANGEUPPERLessAndEqualTo &&
      compression > compressionOfTeam) ||
    (compression <= compressionREDLessAndEqualTo &&
      compression >= compressionREDGreaterAndEqualTo &&
      compression > compressionOfTeam)
  ) {
    overallOutcomeWithoutSameJG = "amber";
  }

  let overallOutCome = overallOutcomeWithoutSameJG;
  if (
    sameJGReportingsTobe !== sameJobGroupReportingGREENGreaterAndEqualTo &&
    (overallOutcomeWithoutSameJG === "amber" ||
      overallOutcomeWithoutSameJG === "green")
  ) {
    overallOutCome = "amber";
  } else if (
    sameJGReportingsTobe === sameJobGroupReportingGREENGreaterAndEqualTo &&
    overallOutcomeWithoutSameJG === "green"
  ) {
    overallOutCome = "green";
  }

  let finalOutcome = "amber";
  let finalOutcomeShortCode = null;
  if (aiOutcome === "red" || overallOutCome === "red") {
    finalOutcome = "red";
    finalOutcome ="R";
  } else if (
    (aiOutcome === "green" || aiOutcome === "amber") &&
    overallOutCome === "green" &&
    compression >= compressionORANGELOWERGreaterAndEqualTo &&
    compression <= compressionORANGELOWERLessAndEqualTo
  ) {
    finalOutcome = "amber";
    finalOutcome ="A";
  } else if (
    (aiOutcome === "green" || aiOutcome === "amber") &&
    overallOutCome === "green"
  ) {
    finalOutcome = "green";
    finalOutcome ="G";
  }

  const id = () => {
    const value = positionId.toString().split("");
    const pad2 = (n) => (n < 10 ? "0" : "") + n;
    const time =
      new Date().getFullYear() +
      pad2(new Date().getMonth() + 1) +
      pad2(new Date().getDate()) +
      pad2(new Date().getHours()) +
      pad2(new Date().getMinutes()) +
      pad2(new Date().getSeconds());
    return value[0] + value[value.length - 1] + time;
  };

  const dateOfSubmission = () => {
    const today = new Date();
    return today.toISOString();
  };

  let outcomeColor = "#0ab15e";
  let overallOutComeTag = "sufficiently healthy";
  let emailOverallOutcomeText =
    "EVA shows that the team structure seems sufficiently healthy to accommodate the requested changes.";
  if (finalOutcome === "red") {
    emailOverallOutcomeText =
      "EVA notices major potential concerns in the health of the team structure and /or selected Management Level(s) as a result of the requested changes. Your direct report has been guided to reconsider and adjust as appropriate and/or confirm your perspective.​ If submitted in its current state, the changes will be escalated for review by the central Job Evaluation Team.";
    overallOutComeTag = "unhealthy";
    outcomeColor = "#dd1d21";
  } else if (finalOutcome === "amber") {
    emailOverallOutcomeText =
      "EVA notices potential concerns in the health of the team structure and / or selected Management Level(s) as a result of the requested changes. Your direct report has been guided to reflect on the EVA observations and recommendations with you, to see whether any adjustments are necessary.​";
    overallOutComeTag = "some challenges identified";
    outcomeColor = "#eb8705";
  }

  let colorCodeSpanControlAsIs = null;
  if (
    spanOfControlOfTeam <= spanOfControlREDLessAndEqualTo &&
    spanOfControlOfTeam >= spanOfControlREDGreaterAndEqualTo
  ) {
    colorCodeSpanControlAsIs = "#dd1d21";
  } else if (
    spanOfControlOfTeam <= spanOfControlORANGELessAndEqualTo &&
    spanOfControlOfTeam >= spanOfControlORANGEGreaterAndEqualTo
  ) {
    colorCodeSpanControlAsIs = "#eb8705";
  } else if (
    spanOfControlOfTeam >= spanOfControlGREENGreaterAndEqualTo &&
    spanOfControlOfTeam <= spanOfControlGREENLessAndEqualTo
  ) {
    colorCodeSpanControlAsIs = "#0ab15e";
  }

  let colorCodeSpanControlToBe = null;
  if (
    (spanOfControlOfTeamToBe <= spanOfControlREDLessAndEqualTo &&
      spanOfControlOfTeamToBe >= spanOfControlREDGreaterAndEqualTo) ||
    spanOfControlOfTeamToBe === 0
  ) {
    colorCodeSpanControlToBe = "#dd1d21";
  } else if (
    spanOfControlOfTeamToBe <= spanOfControlORANGELessAndEqualTo &&
    spanOfControlOfTeamToBe >= spanOfControlORANGEGreaterAndEqualTo
  ) {
    colorCodeSpanControlToBe = "#eb8705";
  } else if (
    spanOfControlOfTeamToBe >= spanOfControlGREENGreaterAndEqualTo &&
    spanOfControlOfTeamToBe <= spanOfControlGREENLessAndEqualTo
  ) {
    colorCodeSpanControlToBe = "#0ab15e";
  }

  let colorCodeCompression = null;
  if (
    compressionOfTeam >= compressionREDGreaterAndEqualTo &&
    compressionOfTeam <= compressionREDLessAndEqualTo
  ) {
    colorCodeCompression = "#dd1d21";
  } else if (
    (compressionOfTeam >= compressionORANGELOWERGreaterAndEqualTo &&
      compressionOfTeam <= compressionORANGELOWERLessAndEqualTo) ||
    (compressionOfTeam >= compressionORANGEUPPERGreaterAndEqualTo &&
      compressionOfTeam <= compressionORANGEUPPERLessAndEqualTo)
  ) {
    colorCodeCompression = "#eb8705";
  } else if (
    compressionOfTeam >= compressionGREENGreaterAndEqualTo &&
    compressionOfTeam <= compressionGREENLessAndEqualTo
  ) {
    colorCodeCompression = "#0ab15e";
  }

  let colorCodeCompressionTobe = null;
  if (
    compression >= compressionREDGreaterAndEqualTo &&
    compression <= compressionREDLessAndEqualTo
  ) {
    colorCodeCompressionTobe = "#dd1d21";
  } else if (
    (compression >= compressionORANGELOWERGreaterAndEqualTo &&
      compression <= compressionORANGELOWERLessAndEqualTo) ||
    (compression >= compressionORANGEUPPERGreaterAndEqualTo &&
      compression <= compressionORANGEUPPERLessAndEqualTo)
  ) {
    colorCodeCompressionTobe = "#eb8705";
  } else if (
    compression >= compressionGREENGreaterAndEqualTo &&
    compression <= compressionGREENLessAndEqualTo
  ) {
    colorCodeCompressionTobe = "#0ab15e";
  }

  let colorCodeMaxLayer = null;
  if (
    (maxNoOfLayersOfTeam || maxNoOfLayersOfTeamToBe) >=
      layersGREENGreaterAndEqualTo &&
    (maxNoOfLayersOfTeam || maxNoOfLayersOfTeamToBe) <=
      layersGREENLessAndEqualTo
  ) {
    colorCodeMaxLayer = "#0ab15e";
  } else if (
    (maxNoOfLayersOfTeam || maxNoOfLayersOfTeamToBe) >=
      layersORANGEGreaterAndEqualTo &&
    (maxNoOfLayersOfTeam || maxNoOfLayersOfTeamToBe) <=
      layersORANGELessAndEqualTo
  ) {
    colorCodeMaxLayer = "#eb8705";
  }

  let sign = "";
  if (coeImpact > 0) {
    sign = `<span >&#43;</span>`;
  }

  let directionOfChange = "";
  if (spanOfControlOfTeamToBe > spanOfControlOfTeam) {
    directionOfChange = `▲`;
  } else if (spanOfControlOfTeamToBe < spanOfControlOfTeam) {
    directionOfChange = `▼`;
  }

  let directionOfChangeForCompression = "";
  if (compression > compressionOfTeam) {
    directionOfChangeForCompression = `▲`;
  } else if (compression < compressionOfTeam) {
    directionOfChangeForCompression = `▼`;
  }

  let nextStepsForOutcome = GREEN_OUTCOME_NEXT_STEPS;
  if (finalOutcome === "amber") {
    nextStepsForOutcome = AMBER_OUTCOME_NEXT_STEPS;
  } else if (finalOutcome === "red") {
    nextStepsForOutcome = RED_OUTCOME_NEXT_STEPS;
  }

  const emailNextStepsForOverallOutcome = nextStepsForOutcome.map((value) => {
    return `
    <tr>
    <th style="vertical-align:top;"><span style="margin: 5px 5px 0 10px;color:#dd1d21">➤</span></th>
    <th style="margin:0 5px;">
    <p style="margin:0;font-size:12.75px;text-align:justify">
    ${ReactDOMServer.renderToStaticMarkup(value)}
    </p>
    </th>
    </tr>
    `;
  });

  const compressionTextOutcomeEmail = (compressionAsIs, compression) => {
    let compressionOutcomeSuggestionTextEmail = "";
    if (
      compression >= compressionORANGELOWERGreaterAndEqualTo &&
      compression <= compressionORANGELOWERLessAndEqualTo &&
      compression > compressionAsIs
    ) {
      compressionOutcomeSuggestionTextEmail =
        "Your team compression improves after the change(s), yet remains lower than best practice. There may be opportunities to further rebalance seniority and reduce costs.";
    } else if (
      compression >= compressionORANGELOWERGreaterAndEqualTo &&
      compression <= compressionORANGELOWERLessAndEqualTo &&
      compression < compressionAsIs
    ) {
      compressionOutcomeSuggestionTextEmail =
        "Your team compression decreases after the change(s), further below best practice. Reconsider opportunities to rebalance seniority and reduce costs.";
    } else if (
      compression >= compressionORANGELOWERGreaterAndEqualTo &&
      compression <= compressionORANGELOWERLessAndEqualTo &&
      compression === compressionAsIs
    ) {
      compressionOutcomeSuggestionTextEmail =
        "Your team compression after the change(s) remains lower than best practice. There may be opportunities to rebalance seniority and reduce costs. ";
    } else if (
      compression >= compressionREDGreaterAndEqualTo &&
      compression <= compressionREDLessAndEqualTo &&
      compression > compressionAsIs
    ) {
      compressionOutcomeSuggestionTextEmail =
        "Your team compression improves after the change(s), yet remains significantly below best practice. Continue to look for opportunities to rebalance seniority and reduce costs. ";
    } else if (
      compression >= compressionREDGreaterAndEqualTo &&
      compression <= compressionREDLessAndEqualTo &&
      compression < compressionAsIs
    ) {
      compressionOutcomeSuggestionTextEmail =
        "Your change(s) result in a compression below the minimum threshold. Reconsider the balance of seniority in the team and increase the proportion of positions with two Management Levels' difference to your own position.";
    } else if (
      compression >= compressionREDGreaterAndEqualTo &&
      compression <= compressionREDLessAndEqualTo &&
      compression === compressionAsIs
    ) {
      compressionOutcomeSuggestionTextEmail =
        "Your change(s) result in a compression below the minimum threshold. Reconsider the balance of seniority in the team and increase the proportion of positions with two Management Levels' difference to your own position.";
    } else if (
      compression >= compressionORANGEUPPERGreaterAndEqualTo &&
      compression <= compressionORANGEUPPERLessAndEqualTo &&
      compression <= compressionAsIs
    ) {
      compressionOutcomeSuggestionTextEmail =
        "Your team compression after the change(s) remains relatively high. This situation indicates a significant difference between your managerial focus versus that of your team members.";
    } else if (
      compression >= compressionORANGEUPPERGreaterAndEqualTo &&
      compression <= compressionORANGEUPPERLessAndEqualTo &&
      compression > compressionAsIs
    ) {
      compressionOutcomeSuggestionTextEmail =
        "Your change(s) result in a relatively high compression, indicating there is a significant difference between your managerial focus versus that of your team members.";
    }
    return compressionOutcomeSuggestionTextEmail;
  };

  const spanOfControlOutcomeTextEmail = (value) => {
    let spanOfControlSuplimentText = "";
    if (
      (value >= spanOfControlREDGreaterAndEqualTo &&
        value <= spanOfControlREDLessAndEqualTo) ||
      value === 0
    ) {
      spanOfControlSuplimentText =
        "Your span of control is significantly lower than best practice. Reflect with your Line Manager whether there are opportunities in the wider organisation to optimise team compositions.";
    } else if (
      value >= spanOfControlORANGEGreaterAndEqualTo &&
      value <= spanOfControlORANGELessAndEqualTo
    ) {
      spanOfControlSuplimentText =
        "Your span of control is significantly lower than best practice. Reflect with your Line Manager whether there are opportunities in the wider organisation to optimise team compositions.";
    }

    return spanOfControlSuplimentText;
  };

  const jgDistributionTextOutcomeEmail = (value) => {
    let jgDistributionText = "";
    if (value <= spreadOverJGsORANGELessAndEqualTo) {
      jgDistributionText =
        "All your direct reports are at the same Management Level. A mix of Management Levels enables staff development, (peer) coaching and succession planning within the team.";
    }

    return jgDistributionText;
  };

  const topWeightTextOutcomeEmail = (topWeight, managementLevel) => {
    let topWeightText = "";
    if (topWeight > topWeightGREENLessAndEqualTo) {
      topWeightText = `Consider changing the Management Level distribution in your team as Management Level <strong style="color:##dd1d21"> ${managementLevel} </strong> positions seem to be over-represented.`;
    }
    return topWeightText;
  };

  const sameJGReportingTextEmail = (value, toBe) => {
    let sameJgText = "";
    if (value !== null && toBe !== sameJobGroupReportingGREENLessAndEqualTo) {
      sameJgText =
        "Within your team you have same grade reporting (where one or more  positions are at the same Management Level as your own), which is an undesired set-up. A minimum of one Management Level difference is expected, best practice is two levels difference on average.";
    }

    return sameJgText;
  };

  // console.log(
  //   sameJGReportingTextEmail(
  //     sameJGReportingsInTeamOfLineManager,
  //     sameJGReportingsTobe
  //   )
  // );

  const maxNoOfLayersTextEmail = (value) => {
    let textOutcome = "";
    if (
      value >= layersORANGEGreaterAndEqualTo &&
      value <= layersORANGELessAndEqualTo
    ) {
      textOutcome =
        "Reflect with your Line Manager whether there are opportunities in the wider organisation to reduce the number of organisational layers.";
    }
    return textOutcome;
  };

  const emailBody = getEmailBody(
    overallOutCome,
    superVisorName,
    overallOutComeTag,
    emailOverallOutcomeText,
    positionTitle,
    positionId,
    jobProfile,
    location,
    teamStructureForOutcomeEmail,
    colorCodeSpanControlAsIs,
    colorCodeSpanControlToBe,
    spanOfControlOfTeam,
    spanOfControlOfTeamToBe,
    directionOfChange,
    directionOfChangeForCompression,
    compressionOfTeam,
    compression,
    colorCodeMaxLayer,
    maxNoOfLayersOfTeam,
    maxNoOfLayersOfTeamToBe,
    sign,
    coe,
    sameJGReportingsInTeamOfLineManager,
    jobDescriptions,
    jobDescriptionLinks,
    outcomeColor,
    colorCodeCompression,
    colorCodeCompressionTobe,
    reasonForActionsBody,
    emailNextStepsForOverallOutcome,
    sameJGReportingsTobe,
    compressionTextOutcomeEmail(compressionOfTeam, compression),
    spanOfControlOutcomeTextEmail(spanOfControlOfTeamToBe),
    jgDistributionTextOutcomeEmail(jgDistributionValue),
    topWeightTextOutcomeEmail(topWeight, managementLevelValue),
    sameJGReportingTextEmail(
      sameJGReportingsInTeamOfLineManager,
      sameJGReportingsTobe
    ),
    maxNoOfLayersTextEmail(maxNoOfLayers),
    aiSupplimentaryTextsForEmail,
    ragIndicatorTable,
    cousinProfileTextForEmail
  );

  const progressChart = ReactDOMServer.renderToStaticMarkup(
    <div className="bar-box">
      <div className="progress-bar">Change or create new position</div>
      <div className="progress-bar">Position updated / Created in EVA</div>
      <div
        className="progress-bar"
        style={{ background: "#fbce07", paddingTop: "22px" }}
      >
        Finalise Modelling
      </div>
      <div className="progress-bar">Trigger your changes in Workday</div>
    </div>
  );

  const handleSubmit = () => {
    const customButtonClasses = Swal.mixin({
      customClass: {
        confirmButton: "change-button confirm",
        cancelButton: "change-button cancel",
      },
      buttonsStyling: false,
    });
    customButtonClasses
      .fire({
        html: `${progressChart}`,
        text: "Finalising completes the EVA process and notifies your line manager of the outcomes",
        title: "Are you sure?",
        imageUrl: warning, 
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Finalise",
        cancelButtonText: "Cancel",
        width: "35%",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setStep(step + 1);
          const outputData = changes.map((value, index) => {
            let actionType = null;
            let jobProfileAsIs = null;
            let jobProfileToBe = null;
            let managementLevelAsIs = null;
            let managementLevelToBe = null;
            let countryAsIs = null;
            let countryToBe = null;
            let aiRetrainingJg = null;
            let jdParsedTextFromAi = null;
            let aiConfidence = null;
            let aiConfidenceJG3 = null;
            let aiConfidenceJG4 = null;
            let aiConfidenceJG5 = null;
            let aiConfidenceJG6 = null;

            let evaRetrainingJg = null;


            if (value.isChanged) {
              actionType = "change";
              jobProfileAsIs = teamDetails.find(
                (v) => v.id === value.positionId
              ).jobProfile;
              jobProfileToBe = value.jobProfile;
              managementLevelAsIs = teamDetails.find(
                (v) => v.id === value.positionId
              ).ManagementLevel;
              managementLevelToBe = Number(value.ManagementLevel);
              countryAsIs = teamDetails.find(
                (v) => v.id === value.positionId
              ).location;
              countryToBe = value.location;

              jdParsedTextFromAi = value.aiResponse.data.JD_Parsed_For_AI;
              aiConfidence = value.aiResponse.data.confidence;
              aiConfidenceJG3 = value.aiResponse.data.result[0][0];
              aiConfidenceJG4 = value.aiResponse.data.result[0][1];
              aiConfidenceJG5 = value.aiResponse.data.result[0][2];
              aiConfidenceJG6 = value.aiResponse.data.result[0][3];
              evaRetrainingJg = value.aiResponse.data.AI_EVA_Retraining_JG.split("")[2];
            } else if (value.isDuplicated) {
              actionType = "duplicate";
              jobProfileToBe = value.jobProfile;
              managementLevelToBe = Number(value.ManagementLevel);
              countryToBe = value.location;
            } else if (value.isRemoved) {
              actionType = "remove";
              jobProfileAsIs = value.jobProfile;
              jobProfileToBe = null;
              managementLevelAsIs = Number(value.ManagementLevel);
              managementLevelToBe = null;
              countryAsIs = value.location;
              countryToBe = null;
            } else if (value.isNewPosition) {
              actionType = "new";
              jobProfileToBe = value.jobProfile;
              managementLevelToBe = Number(value.ManagementLevel);
              countryToBe = value.location;

              jdParsedTextFromAi = value.aiResponse.data.JD_Parsed_For_AI;
              aiConfidence = value.aiResponse.data.confidence;
              aiConfidenceJG3 = value.aiResponse.data.result[0][0];
              aiConfidenceJG4 = value.aiResponse.data.result[0][1];
              aiConfidenceJG5 = value.aiResponse.data.result[0][2];
              aiConfidenceJG6 = value.aiResponse.data.result[0][3];
              evaRetrainingJg = value.aiResponse.data.AI_EVA_Retraining_JG.split("")[2];
            }

            let posId = value.positionId;
            if (value.isNewPosition || value.isDuplicated) {
              posId = null;
            }

            const object = {};
            object.JE_Case_Id = id();
            object.JE_Case_Number = index + 1;
            object.LM_Position_ID = positionId;
            object.LM_Position_Title = positionTitle;
            object.LM_EC = lmEC;
            object.LM_EVP_Grouping = lmEvpGrouping;
            object.LM_Org_Unit_5 = lmOrgUnit5;
            object.Date_Of_Submission = dateOfSubmission();
            object.Action_Type = actionType;
            object.Reason_For_Action = value.comments;
            object.Position_ID = posId;
            object.Position_Title = value.positionTitle;
            object.Job_Profile_As_Is = jobProfileAsIs;
            object.Job_Profile_To_Be = jobProfileToBe;
            object.Management_Level_As_Is = managementLevelAsIs;
            object.Management_Level_To_Be = value.initialManagementLevel;
            object.Outcome_Scenario = finalOutcome;
            object.Span_Of_Control_As_Is = spanOfControlOfTeam;
            object.Span_Of_Control_To_Be = spanOfControlOfTeamToBe;
            object.JG_Compression_As_Is = compressionOfTeam;
            object.JG_Compression_To_Be = parseFloat(compression.toFixed(2));
            object.Org_Level_Of_Direct_Reports_As_Is = maxNoOfLayersOfTeam;
            object.Org_Level_Of_Direct_Reports_To_Be = maxNoOfLayersOfTeamToBe;
            object.Total_Same_JG_Reporting_In_Team_As_Is =
              sameJGReportingsInTeamOfLineManager;
            object.Total_Same_JG_Reporting_In_Team_To_Be = sameJGReportingsTobe;
            object.Top_Weight = parseFloat(topWeight.toFixed(2));
            object.Spread_Over_JGs = jgDistribution().length;
            object.Country_Name_For_COE_As_Is = countryAsIs;
            object.Country_Name_For_COE_To_Be = countryToBe;
            object.Change_In_Team_Cost_Of_Employment = coeImpact;
            object.JD_Uploaded = value.jdUrl === null ? "no" : "yes";
            object.JD_File_Name = value.jdUrl;
            object.Spot_Check_Status = null;
            object.Spot_Check_Performed_By = null;
            object.Spot_Check_Date = null;
            object.Spot_Check_Outcome = null;
            object.Spot_Check_Comment = null;
            object.Spot_Check_Comment_Free_Form = null;
            object.AI_Predicted_Job_Group = evaRetrainingJg;
            object.AI_Confidence_of_Predicted_JG = aiConfidence;
            object.AI_Probability_Spread_JG3 = aiConfidenceJG3;
            object.AI_Probability_Spread_JG4 = aiConfidenceJG4;
            object.AI_Probability_Spread_JG5 = aiConfidenceJG5;
            object.AI_Probability_Spread_JG6 = aiConfidenceJG6;
            object.JG_Selected_Final = managementLevelToBe;
            object.Job_Family_Group_Of_Position = value.jobFamily;
            object.AI_EVA_Retraining_JG = null;
            object.JD_Parsed_For_AI = jdParsedTextFromAi;
            object.Spot_Check_JD_Dowloaded = null;
            object.JG_Of_LM = managementLevelOfLineManger;
            object.Email_Address_Of_LM = email;
            object.AI_Retraining_File_Name = null;
            object.Load_DateTime = dateOfSubmission();
            object.Modified_DateTime = dateOfSubmission();
            object.Load_User = name;
            object.Modified_User = name;
            return object;
          });

          const jdLinks = [];
          changes.forEach((element) => {
            if (element.jdUrl !== null) {
              jdLinks.push(
                axios.post(
                  `${process.env.REACT_APP_API_URL}/delete-saved-files`,
                  { file: element.jdUrl }
                )
              );
            }
          });

          const progressChartFinal = ReactDOMServer.renderToStaticMarkup(
            <div className="bar-box" style={{ marginTop: "20px" }}>
              <div className="progress-bar">Change or create new position</div>
              <div className="progress-bar">
                Position updated / Created in EVA
              </div>
              <div className="progress-bar" style={{ paddingTop: "22px" }}>
                Finalise Modelling
              </div>
              <div className="progress-bar" style={{ background: "#fbce07" }}>
                Trigger your changes in Workday
              </div>
            </div>
          );

          try {
            await axios.all([
              axios.post(`${process.env.REACT_APP_API_URL}/email`, {
                lmEvpGrouping,
                cc:
                  process.env.NODE_ENV === "development"
                    ? "Karthika.Rajeev@shell.com"
                    : email,
                to:
                  process.env.NODE_ENV === "development"
                    ? "Karthika.Rajeev@shell.com"
                    : supervisorEmail,
                bcc: "EVA-no-reply@shell.com",
                content: emailBody,
                caseId: outputData[0].JE_Case_Id,
                outcome: `${finalOutcome.toUpperCase()} - ${lmEvpGrouping}`,
              }),
              axios.post(`${process.env.REACT_APP_API_URL}/submit`, outputData),
              Promise.all(jdLinks),
            ]);
            const customButtonClasses = Swal.mixin({
              customClass: {
                confirmButton: "change-button confirm",
                container: "swal-wide",
              },
              buttonsStyling: false,
            });
            await customButtonClasses.fire({
              imageUrl: success, 
              imageWidth: 75,
              imageHeight: 75,
              imageAlt: "success",
              html: `<p style="margin:0;
              font-size:15px;
              text-align:left;
              padding:5px 0 0 5px;
              font-weight:700;">
              ◉ Outcomes have now been sent to your Line Manager and yourself.</p>
              <p style="margin:0;
              font-size:15px;
              text-align:left;
              padding:5px 0 0 5px;
              font-weight:700;">◉ Next steps are provided on the following screen​.</p>
              <p style="margin:0;
              font-size:15px;
              color: #dd1d21;
              text-align:left;
              padding:5px 0 0 5px;
              font-weight:700;">◉ Remember to action your changes in Workday.</p>
              ${progressChartFinal}`,
              // showConfirmButton: true,
              confirmButtonText: "OK",
              buttonsStyling: false,
              customClass: {
                confirmButton: "change-button confirm",
              },
              width: "35%",
            }).then(async (result) => {
              dispatch(onSubmission());
              setStep(step + 2);
              setCaseId(outputData[0].JE_Case_Id);
              exportPDFWithComponent();
            });
          } catch (error) {
            if (error) {
              Swal.fire({
                imageUrl: warning, 
                imageWidth: 75,
                imageHeight: 75,
                imageAlt: "warning",
                title: "Unable to finalise",
                html: ` <p style="margin:5px 0;font-size:15px;text-align:left;font-weight:700;"><span style="color:#dd1d21;">●</span> Something went wrong, likely an email address or delivery issue.</p><p style="margin:5px 0;font-size:15px;text-align:left;font-weight:700;"><span style="color:#dd1d21;">●</span> The outcomes could not be sent to your line manager and the modelling could not be saved.
                </p><p style="margin:5px 0;font-size:15px;text-align:left;font-weight:700;color:#dd1d21"><span style="color:#dd1d21;">●</span> Please contact HR Systems Support.</p>`,
                width: "675px",
                showConfirmButton: true,
              }).then((res) => {
                setStep(0);
                axios
                  .post(`${process.env.REACT_APP_API_URL}/insert-saved-files`, {
                    caseId: outputData[0].JE_Case_Id,
                  })
                  .then((res) => {
                    axios.post(
                      `${process.env.REACT_APP_API_URL}/delete-saved-data`,
                      { caseId: outputData[0].JE_Case_Id }
                    );
                  });
              });
            }
          }
        }
      });
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };



  let outComeButtons = (
    <div className="outcome-buttons">
      <div className="outcome-button">
        <Button disabled={isSubmitted} onClick={() => history.goBack()}>
          REDO MODELLING
        </Button>
      </div>
      <div className="outcome-button">
        <Button disabled={isSubmitted} onClick={handleSubmit}>
          FINALISE MODELLING
        </Button>
      </div>
    </div>
  );

  if (step === 2 && isSubmitted) {
    outComeButtons = (
      <div className="outcome-buttons">
        <div className="outcome-button">
          <Button onClick={exportPDFWithComponent}>SAVE AS PDF</Button>
        </div>
      </div>
    );
  }

  let content = null;

  if (step === 0 || (step === 2 && isSubmitted)) {
    content = (
      <React.Fragment>
        <div className="container-outcome">
          <PDFExport
            ref={pdfExportComponent}
            fileName={`EVA_${overallOutCome.charAt(0).toUpperCase()}_${caseId}`}
            paperSize="auto"
            scale={1}
            margin=".75cm"
            orientation="landscape"
          >
            <div className="top-bar-outcome">
              <h2
                style={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  fontSize: "1.15rem",
                }}
              >
                {isSubmitted && caseId
                  ? "Next Steps"
                  : "Preview of Modelling Outcome"}
              </h2>
            </div>
            <div className="outcome-items">
              <OutcomeLeft
                overallOutCome={finalOutcome}
                isSubmitted={isSubmitted}
                compression={Number(compression).toFixed(2)}
                topWeight={topWeight}
                managementLevelOneBelow={managementLevelOneBelow}
                jgDistribution={jgDistribution()}
                id={caseId}
                nextStepsForOutcome={nextStepsForOutcome}
                sameJGReportingsTobe={sameJGReportingsTobe}
                outComeButtons={outComeButtons}
              />
              <OutcomeRight
                compressionOfTeamToBe={Number(compression).toFixed(2)}
                overallOutCome={finalOutcome}
                isSubmitted={isSubmitted}
                id={caseId}
                jdLinks={jobDescriptions}
                sameJGReportingsTobe={sameJGReportingsTobe}
                sameJGReportingAsIs={sameJGReportingsInTeamOfLineManager}
              />
            </div>
          </PDFExport>
        </div>
      </React.Fragment>
    );
  } else if (step === 1) {
    content = (
      <div className="loader-container-outcome">
        <Spinner />
        <h3 className="loader-notes">
          Please wait while we are submitting changes...
        </h3>
      </div>
    );
  }

  return content;
};

export default OutcomeSheet;
