import React from "react";
export const getObject = (values) =>
  values.map((value) => {
    const object = {};
    object.value = value.toUpperCase();
    object.label = value.toUpperCase();
    return object;
  });

export const createOptions = (values) =>
  values.map((value) => {
    const object = {};
    object.value = value;
    object.label = value;
    return object;
  });

export const getObjectCapitalize = (values) =>
  values.map((value) => {
    const object = {};
    object.value = value;
    object.label = value.toUpperCase();
    return object;
  });
  export const GREEN_OUTCOME_NEXT_STEPS = [
    <span>Go to <a href="https://wd3.myworkday.com/shell/d/home.htmld" target="_blank">🔗 Workday</a> or contact HR Advice to action the relevant positions. Don't forget to (also) action any positions that you have removed. Attach the PDF or email to your request</span>,
    <span>Consult your HR Advisor on required steps in line with relevant policy/legal requirements. These steps may include local consultation requirements and/or following specific resourcing guidelines (covering recruitment, lateral transfers, promotions or downgrading - as applicable).</span>,
  ];
  //${React.createElement('a', { href: 'www.google.com'}, 'Workday')}
  export const AMBER_OUTCOME_NEXT_STEPS = [
  <span>Go to <a href="https://wd3.myworkday.com/shell/d/home.htmld" target="_blank">🔗 Workday</a> or contact HR Advice to action the relevant positions. Don't forget to (also) action any positions that you have removed. Attach the PDF or email to your request.</span>,
  <span>Consult your HR Advisor on required steps in line with relevant policy/legal requirements. These steps may include local consultation requirements and/or following specific resourcing guidelines (covering recruitment, lateral transfers, promotions or downgrading - as applicable).​</span>,
  ];
  
  
  export const RED_OUTCOME_NEXT_STEPS = [
    <span>Confirm support from your Line Manager for your changes.​</span>,
    <span>Raise your request with HR Advice or in <a href="https://wd3.myworkday.com/shell/d/home.htmld" target="_blank">🔗 Workday</a>.​</span>,
    <span>The central Job Evaluation Team will contact you for clarification.</span>,
    <span>If the change is approved, consult with your HR Advisor on required steps in line with relevant policy/legal requirements. These steps may include local consultation requirements and/or following specific resourcing guidelines (covering recruitment, lateral transfers, promotions or downgrading - as applicable).​</span>,
  ];
  

export const connectHRLink =
  "https://eu001-sp.shell.com/sites/AAAAB5282/Public/Pages/en_GL/Content/Tier0/Global/sc_contact.aspx?RoleType=Home&CPTitle=HR%20Services%20Contacts";

export const hrAdviceRepresentative =
  "https://eu001-sp.shell.com/sites/AAAAB5282/Public/Pages/en_GL/Content/Tier0/Global/sc_contact.aspx?RoleType=Home&CPTitle=HR%20Services%20Contacts";

export const welcometoEVAVideo = 
  "https://eu001-sp.shell.com/:v:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3/Welcome%20to%20EVA%20March%202022.mp4?csf=1&web=1&e=KSbI8T";

export const evaNavigationVideo = 
  "https://eu001-sp.shell.com/:v:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3/EVA%20Navigation%20video%20March%202022%20v2.mp4?csf=1&web=1&e=kXzST1";

export const evaUserGuide =
  "https://eu001-sp.shell.com/:b:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3/EVA%20User%20Guide%20for%20Line%20Managers%20v.March%202022.pdf?csf=1&web=1&e=GkYPSI";

export const evaUserGuideShort =
  "https://eu001-sp.shell.com/:b:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3/EVA%20User%20Guide%20(short)%20v.%20March%202022.pdf?csf=1&web=1&e=jnpIjZ";

export const spanOfControlLink =
  "https://eu001-sp.shell.com/:v:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%201%20All%20About%20Job%20Evaluation/Span%20of%20Control%20Final.mp4?csf=1&web=1&e=dLxlip";

export const managementLevelCompression =
  "https://eu001-sp.shell.com/:v:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%201%20All%20About%20Job%20Evaluation/Management%20Level%20Compression%20Revised.mp4?csf=1&web=1&e=w9Dy9p";

export const organisationLayers =
  "https://eu001-sp.shell.com/:v:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%201%20All%20About%20Job%20Evaluation/Organisational%20Layers%20Final.mp4?csf=1&web=1&e=wQbvIu";

export const managementLevelProbabilityAnalysis = 
  "https://eu001-sp.shell.com/:b:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3/Management%20Level%20indication%20March%202022.pdf?csf=1&web=1&e=m5gaJM";

export const jobTitleNamingConventions =
  "https://eu001-sp.shell.com/sites/AAAAA0313/HRBNP/Employee/Forms/AllItems.aspx?id=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3%2FJob%20Title%20Naming%20Conventions.pdf&parent=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3";

export const allAboutJobProfiles =
  "https://eu001-sp.shell.com/:b:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3/All%20About%20Job%20Profiles.pdf?csf=1&web=1&e=0cYJU3";

export const lineManagerGuidanceOnManagementLevels =
  "https://eu001-sp.shell.com/sites/AAAAA0313/HRBNP/Employee/Forms/AllItems.aspx?id=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%201%20All%20About%20Job%20Evaluation%2FLine%20Manager%20Guidance%20on%20Management%20Levels%2Epdf&parent=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%201%20All%20About%20Job%20Evaluation";

export const reachOutToUsLink =
  "https://eu001-sp.shell.com/sites/AAAAB5282/Public/Pages/en_GL/Content/Tier0/Global/sc_contact.aspx?RoleType=Home&CPTitle=HR%20Services%20Contacts";

export const settingManagementLevelsAtShell =
  "https://eu001-sp.shell.com/:b:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Introduction/Setting%20Management%20Levels%20at%20Shell%20v.%20March%202022.pdf?csf=1&web=1&e=4T36Yc";

export const HROLJobEvaluationForLMPage =
  "https://shellhronline.force.com/HROnlineEC/s/article/Job-Evaluation-for-Line-Managers";

export const welcomePageSecondLink =
  "https://eu001-sp.shell.com/:b:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%201%20All%20About%20Job%20Evaluation/Line%20Manager%20Guidance%20on%20Changes%20to%20Team%20Structure.pdf?csf=1&web=1&e=wM25qc";

export const systemSupportLink =
  "https://shellhrservices.my.site.com/HROnlineEC/s/hep-webform-template/a2i07000000CarzAAC/ask-eva-tech-form";
